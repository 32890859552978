.my-spin.ant-spin-dot {
  background-color: #b6181c;
  background: #b6181c;
}

.ant-spin .ant-spin-dot-item{
  background-color: #b6181c;
}


.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #b6181c;
    --bs-btn-border-color: #b6181c;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #b6181dda;
    --bs-btn-hover-border-color: #b6181dda;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: rgb(102, 23, 23,0.99);
    --bs-btn-active-border-color: rgb(102, 23, 23,0.99);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #b6181d8f;
    --bs-btn-disabled-border-color: #b6181d8f;
}

.ant-tabs-ink-bar {
    position: absolute;
    background: #b6181c;
    pointer-events: none
  }

  .ant-btn-primary{
    background: #b6181c;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #b6181c;
    text-shadow: 0 0 .25px currentcolor
  }
  .ant-tabs .ant-tabs-ink-bar{
    background-color: #b6181c;
  }

  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: #b6181c;
    background-color: #b6181c;
  }

  .ant-input-search .ant-input-search-button {
    border-color: #b6181c;
    background-color: #b6181c;
  }

  .ant-tabs-tab-btn
  {
    color: #b6181c;
    text-shadow: 0 0 .25px currentcolor
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #b6181c;
    text-shadow: 0 0 .25px currentcolor
   }


.ant-switch-checked:focus {
    box-shadow: 0 0 0 2px #b6181c
  }
  
  .ant-switch:focus:hover {
    box-shadow: none
  }
  
  .ant-switch-checked {
    background-color: #b6181c
  }